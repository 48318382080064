import firebase, { db } from '@/plugins/firebase'
import VehicleInfo from '../../functions/src/types/vehicle/info'
import { VehicleSecond } from '../../functions/src/types/vehicle/second'

export class Part {
  constructor (
    readonly device: firebase.firestore.DocumentReference,
    readonly info: VehicleInfo,
    readonly seconds: VehicleSecond[],
    readonly firstTime: Date,
    readonly lastTime: Date,
    readonly company: firebase.firestore.DocumentReference | null,
    readonly group: firebase.firestore.DocumentReference | null,
    readonly createdAt?: Date
  ) {

  }
}

export const converter: firebase.firestore.FirestoreDataConverter<Part> = {
  toFirestore (model: Partial<Part>) {
    return model
  },
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot) {
    const data = snapshot.data()

    return new Part(
      data.device,
      data.info,
      data.seconds,
      data.firstTime ? data.firstTime.toDate() : null,
      data.lastTime ? data.lastTime.toDate() : null,
      data.company,
      data.group,
      data.createdAt ? data.createdAt.toDate() : null
    )
  }
}
export default db.collection('parts').withConverter(converter)
